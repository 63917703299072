import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { sortableContainer, sortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';

const SortableItem = sortableElement(({ children, disabled }) => <div disabled={disabled}>{children}</div>);

const SortableContainer = sortableContainer(({ children }) => {
  return <div>{children}</div>;
});

export default function DraggableGrid({ children }) {
  const clonedChildren = React.Children.map(children, (child, index) => React.cloneElement(child, { key: index }));

  const [items, setItems] = useState(clonedChildren);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setItems(arrayMove(items, oldIndex, newIndex));
  };

  return (
    <SortableContainer useDragHandle onSortEnd={onSortEnd} lockAxis="y">
      {items.map((child, index) => (
        <SortableItem key={child.key} index={index} disabled={child.props.disabled}>
          {child}
        </SortableItem>
      ))}
    </SortableContainer>
  );
}

DraggableGrid.propTypes = {
  children: PropTypes.node.isRequired,
};
