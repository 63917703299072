/* eslint-disable no-unused-expressions */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState, useEffect } from 'react';
import { makeStyles, ButtonBase, Slider, Typography } from '@material-ui/core';

import PlayIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#ffffff',
    borderRadius: 4,
    width: '250px',
  },
  spacing: {
    padding: theme.spacing(0, 2),
  },
  slider: {
    color: '#000000',
  },
}));

const displayTimer = timer => {
  const date = new Date(0);
  date.setSeconds(timer);

  return date.toISOString().substr(14, 5);
};

const AudioPlayer = ({ id, recordedMessage }) => {
  const classes = useStyles();

  const [duration, setDuration] = useState(0);
  const [curTime, setCurTime] = useState(0);
  const [playing, setPlaying] = useState(false);
  const [clickedTime, setClickedTime] = useState();

  const handleChange = (event, newValue) => {
    setClickedTime(newValue);
  };

  useEffect(() => {
    const audio = document.getElementById(`audio-${id}`);

    const setAudioData = () => {
      setDuration(audio.duration);
      setCurTime(audio.currentTime);
    };

    const setAudioTime = () => setCurTime(audio.currentTime);
    audio.addEventListener('loadeddata', setAudioData);
    audio.addEventListener('timeupdate', setAudioTime);

    playing ? audio.play() : audio.pause();

    if (clickedTime && clickedTime !== curTime) {
      audio.currentTime = clickedTime;
      setClickedTime(null);
    }

    if (curTime === duration) {
      setPlaying(false);
    }

    return () => {
      audio.removeEventListener('loadeddata', setAudioData);
      audio.removeEventListener('timeupdate', setAudioTime);
    };
  }, [playing, clickedTime, curTime, duration]);

  return (
    <div className={classes.root}>
      <div className={classes.spacing}>
        {playing ? (
          <ButtonBase onClick={() => setPlaying(false)}>
            <PauseIcon color="secondary" />
          </ButtonBase>
        ) : (
          <ButtonBase onClick={() => setPlaying(true)}>
            <PlayIcon color="secondary" />
          </ButtonBase>
        )}
      </div>

      <Typography variant="subtitle2" className={classes.spacing}>
        {displayTimer(curTime)}
      </Typography>

      <Slider value={curTime} step={0.1} min={0} max={duration} onChange={handleChange} className={classes.slider} />

      <audio id={`audio-${id}`}>
        <source src={recordedMessage} type="audio/mp3" />
      </audio>

      <Typography variant="subtitle2" className={classes.spacing}>
        {displayTimer(duration)}
      </Typography>
    </div>
  );
};

export default AudioPlayer;
