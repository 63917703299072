import React, { forwardRef } from 'react';
import MaterialTable from '@material-table/core';
import { makeStyles, Paper } from '@material-ui/core';

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
// import DeleteOutline from 'src/assets/icons/close.svg';
import DeleteOutline from 'src/assets/icons/delete.svg';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const useStyles = makeStyles(() => ({
  root: {
    '& table': {
      borderCollapse: 'separate',
      borderSpacing: '0 10px',
      backgroundColor: '#f6f6f6',
    },
    '& tr': {
      backgroundColor: '#fff',
    },
    '& td': {
      border: 0,
    },
    '& th': {
      border: 0,
    },
    '& td:first-child': {
      borderTopLeftRadius: 5,
      borderBottomLeftRadius: 5,
      paddingLeft: '12px',
    },
    '& td:last-child': {
      borderTopRightRadius: 5,
      borderBottomRightRadius: 5,
    },
    '& th:first-child': {
      borderTopLeftRadius: 5,
      borderBottomLeftRadius: 5,
      paddingLeft: '12px',
    },
    '& th:last-child': {
      borderTopRightRadius: 5,
      borderBottomRightRadius: 5,
    },
  },
}));

export default function Table(props) {
  const { components, options } = props;
  const classes = useStyles();
  return (
    <MaterialTable
      {...props}
      icons={tableIcons}
      components={{
        Container: containerProps => <Paper {...containerProps} elevation={0} className={classes.root} />,
        ...components,
      }}
      options={{
        search: false,
        showTitle: false,
        toolbar: false,
        paging: false,
        draggable: false,
        actionsColumnIndex: -1,
        ...options,
        headerStyle: {
          fontFamily: 'Lato',
          fontSize: 12,
          fontWeight: 700,
          ...options.headerStyle,
        },
        rowStyle: {
          fontSize: 13,
          color: '#8f909c',
          height: 74,
          whiteSpace: 'nowrap',
          fontWeight: 400,
          ...options.rowStyle,
        },
      }}
    />
  );
}

export { tableIcons };
