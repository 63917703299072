import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getLoading, getRepLabels, getRepsLoading, getReps, getCountries, getCountriesLoading } from './selectors';
import { actions } from '../../../repLabels/slice';
import { actions as repActions } from '../../../reps/slice';

export const useRepLabelsActions = () => {
  const dispatch = useDispatch();

  const createRepLabel = useCallback(repLabel => dispatch(actions.createRepLabel(repLabel)), [dispatch]);
  const removeRepLabel = useCallback(repLabelId => dispatch(actions.removeRepLabel(repLabelId)), [dispatch]);
  const updateRepLabel = useCallback(repLabel => dispatch(actions.updateRepLabel(repLabel)), [dispatch]);

  return {
    createRepLabel,
    removeRepLabel,
    updateRepLabel,
  };
};

export const useRepLabelsData = () => {
  const data = useSelector(getRepLabels);
  const isLoading = useSelector(getLoading);

  return {
    data,
    isLoading,
  };
};

export const useFetchRepLabels = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.getRepLabels());
  }, [dispatch]);
};

export const useRepsActions = () => {
  const dispatch = useDispatch();

  const createRep = useCallback(repData => dispatch(repActions.createRep(repData)), [dispatch]);
  const removeRep = useCallback(repId => dispatch(repActions.removeRep(repId)), [dispatch]);
  const updateRep = useCallback(repData => dispatch(repActions.updateRep(repData)), [dispatch]);

  return {
    createRep,
    removeRep,
    updateRep,
  };
};

export const useRepsData = () => {
  const data = useSelector(getReps);
  const isLoading = useSelector(getRepsLoading);

  return {
    data,
    isLoading
  };
};

export const useFetchReps = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(repActions.getReps());
  }, [dispatch]);
};


export const useCountriesData = () => {
  const data = useSelector(getCountries);
  const isLoading = useSelector(getCountriesLoading);

  return {
    data,
    isLoading,
  };
};

export const useFetchCountries = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(repActions.getFiltersCountries());
  }, [dispatch]);
};