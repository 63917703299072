import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getGroupId, getCallsPage, getCallsFilterFrom, getCallsFilterTo, getCallsFilterRep } from './selectors';
import { actions } from './slice';

export const useCallRecordingsActions = () => {
  const dispatch = useDispatch();

  const setPage = useCallback(callId => dispatch(actions.setPage(callId)), [dispatch]);
  const deleteCallRecording = useCallback(callId => dispatch(actions.deleteCallRecording(callId)), [dispatch]);
  const getCallRecordings = useCallback(params => dispatch(actions.getCallRecordings(params)), [dispatch]);

  return {
    setPage,
    deleteCallRecording,
    getCallRecordings,
  };
};

export const useFetchCallRecordings = () => {
  const dispatch = useDispatch();

  const groupId = useSelector(getGroupId);
  const page = useSelector(getCallsPage);
  const from = useSelector(getCallsFilterFrom);
  const to = useSelector(getCallsFilterTo);
  const rep = useSelector(getCallsFilterRep);

  useEffect(() => {
    dispatch(actions.getCallRecordings({ groupId, page, from, to, rep }));
  }, [dispatch, groupId, page, from, to, rep]);
};
