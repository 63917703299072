import { repLabelsSelectors } from 'src/features/repLabels/slice';
import { repsSelectors } from 'src/features/reps/slice';

export const getPage = state => state.repLabels;

export const getLoading = state => state.repLabels.loading;

export const getError = state => state.repLabels.error;

export const getRepLabels = state => repLabelsSelectors.selectAll(state.repLabels.list);

export const getRepsLoading = state => state.reps.loading;

export const getRepsItemLoading = state => state.reps.loadingItem;

export const getRepsError = state => state.reps.error;

export const getReps = state => repsSelectors.selectAll(state.reps.list);

export const getCountries = state => repsSelectors.selectAll(state.reps.filters.countries.list);

export const getCountriesLoading = state => state.reps.filters.countries.loading;