export const getCallsFilterFrom = state => state.me.callRecordings.filterBy.filter.from;

export const getCallsFilterTo = state => state.me.callRecordings.filterBy.filter.to;

export const getCallsFilterRep = state => state.me.callRecordings.filterBy.filter.rep;

export const getGroupId = state => state.global.groupList.selectedGroup;

export const getCalls = state => state.calls.list;

export const getCallsPage = state => state.calls.page;

export const getCallsTotalCount = state => state.calls.totalCount;

export const getIsLoading = state => state.calls.isLoading;

export const getError = state => state.calls.error;
