import React from 'react';

import SEO from 'src/components/seo';
import GridLayout from 'src/components/draggableGrid';

import FilterBy from 'src/features/callRecordings/filterBy';
import Calls from 'src/features/callRecordings/calls';

export default function Onboarding() {
  return (
    <>
      <SEO title="Call recordings" />

      <GridLayout>
        <FilterBy />

        <Calls />
      </GridLayout>
    </>
  );
}
