import React from 'react';
import { connect } from 'react-redux';

import { makeStyles, Grid, FormControl, Select, MenuItem, Typography } from '@material-ui/core';

import Tab from 'src/components/tab';
import DateRangePicker from 'src/components/dateRangePicker';
import LoadingContainer from 'src/components/common/loading/LoadingContainer';

import { phoneFormatterSeparated } from 'src/helpers/phoneFormatterHelper';

import { useFetchReps, useRepsData } from 'src/features/routingRules/rep/state/hooks';
import { setPage } from 'src/features/callRecordings/calls/slice';
import { setTabExpend, setFilterRep, setFilterDate } from './slice';

const useStyles = makeStyles(() => ({
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  loadingContainer: {
    minHeight: '100%',
  },
}));

const FilterBy = props => {
  const classes = useStyles();

  const { isTabExpended } = props;

  const { data: repList, isLoading } = useRepsData();

  useFetchReps();

  const handelExpand = () => {
    props.setTabExpend(!isTabExpended);
  };

  const handleChangeDate = (data) => {
    props.setFilterDate(data);
    props.setPage(0);
  };

  const handleChangeRep = (event, data) => {
    props.setFilterRep(event.target.value);
    props.setPage(0);
  };

  return (
    <Tab title="Filter by" onClick={handelExpand} expanded={isTabExpended}>
      <Grid container spacing={5}>
        <Grid item xs={3}>
          <div>
            <FormControl variant="outlined" color="secondary" size="small" margin="dense" fullWidth>
              <Typography variant="overline" gutterBottom>
                Date
              </Typography>

              <DateRangePicker onChange={handleChangeDate} />
            </FormControl>
          </div>
        </Grid>

        <Grid item xs={3}>
          <LoadingContainer classNames={classes.loadingContainer} loading={isLoading}>
            <FormControl variant="outlined" color="secondary" size="small" margin="dense" fullWidth>
              <Typography variant="overline" gutterBottom>
                Rep
              </Typography>

              <Select defaultValue="0" onChange={(event, data) => handleChangeRep(event, data)} name="rep">
                <MenuItem value={0}>All reps</MenuItem>
                {
                  repList.map(({ id, repName, country, areaCode, phone }) => (
                    <MenuItem
                      key={id}
                      value={id}
                      id={id}
                    >
                      {`${repName} ${phoneFormatterSeparated(country, areaCode, phone)}` ?? ''}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </LoadingContainer>
        </Grid>
      </Grid>
    </Tab>
  );
};

const mapStateToProps = ({ me }) => {
  const { callRecordings } = me;
  const { filterBy, calls } = callRecordings;

  return { ...filterBy, page: calls.page };
};

const mapDispatchToProps = { setTabExpend, setFilterRep, setFilterDate, setPage };

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(FilterBy));
