/* eslint-disable import/prefer-default-export */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  makeStyles,
  Menu,
  MenuItem,
  IconButton,
} from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

import Loader from 'src/images/loader1.gif';

import checkRole from 'src/helpers/aclHelper';

import MaterialTable from 'src/components/materialTable';
import AudioPlayer from 'src/components/audioPlayer';

import { getGroupId, getCallsFilterFrom, getCallsFilterTo, getCallsFilterRep } from './selectors';
import { useCallRecordingsActions } from './hooks';
import { getMe } from 'src/features/users/state/selectors';

const useStyles = makeStyles(theme => ({
  overlayLoading: {
    backdropFilter: 'blur(3px)',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  showButton: {
    backgroundColor: 'black',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    fontWeight: 'bolder',
    cursor: 'pointer',
  }
}));

const DropDownMenu = props => {
  const { id, recording, handleDeleteRow } = props;

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = event => {
    setAnchorEl(null);
  };

  const handleDownload = event => {
    setAnchorEl(null);

    window.open(recording)
  };

  const handleDelete = event => {
    setAnchorEl(null);

    handleDeleteRow(id);
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreHorizIcon color="secondary" />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem onClick={handleDownload} id="download">
          Download
        </MenuItem>

        <MenuItem onClick={handleDelete} id="delete">
          Delete
        </MenuItem>
      </Menu>
    </>
  );
};

export default function Calls() {
  const classes = useStyles();

  const tableRef = React.createRef();

  const groupId = useSelector(getGroupId);
  const from = useSelector(getCallsFilterFrom);
  const to = useSelector(getCallsFilterTo);
  const rep = useSelector(getCallsFilterRep);
  const userData = useSelector(getMe);

  const { deleteCallRecording, getCallRecordings } = useCallRecordingsActions();

  const [onDeleteDialog, setOnDeleteDialog] = useState(false);

  const [onDeleteDialogId, setOnDeleteDialogId] = useState(null);

  const [showCallDetailsDialog, setShowCallDetailsDialog] = useState(false);

  const [summaryUrlHref, setSummaryUrlHref] = useState(null);

  const columns = [
    {
      title: 'Id',
      field: 'id',
      hidden: true
    },
    {
      title: 'Contact',
      field: 'leadName',
      render: rowData => (<>
        <div style={{ fontWeight: 700 }}>{rowData.leadName} </div>
        <div>{rowData.leadPhone} </div>
      </>),
    },
    {
      title: 'Source',
      field: 'leadSource',
      render: rowData => (<>
        <div>{rowData.leadSource}</div>
      </>),
    },
    {
      title: 'Rep ID',
      field: 'repPhoneNumber',
      sorting: false,
      render: rowData => (<>
        <div>{rowData.repPhoneNumber}</div>
      </>),
    },
    {
      title: 'Date/time',
      field: 'callStartTime',
      render: rowData => (<>
        <div>{rowData.callStartTime}</div>
      </>),
    },
    {
      title: 'Recording',
      field: 'twilioRecordingUrl',
      render: rowData => <AudioPlayer
        id={rowData.id}
        recordedMessage={rowData.twilioRecordingUrl}
      />,
    },
    {
      title: 'Score',
      field: 'callScore',
      hidden: userData?.customer?.ai_summary_enabled === false ? true : false,
      render: rowData => (<>
        <div>{rowData.callScore}</div>
      </>),
    },
    {
      title: 'Call details',
      field: 'callScore',
      hidden: userData?.customer?.ai_summary_enabled === false ? true : false,
      sorting: false,
      render: rowData => (<>
        <div>
          {
            (rowData.callDetails != 'button')
              ? rowData.callDetails
              : (<button onClick={()=>{handleShowCallDetailsDialog(true, rowData.summaryUrl)}} className={classes.showButton}>show</button>)
          }
        </div>
      </>),
    },
    checkRole(['admin', 'manager']) ? {
      field: '',
      title: '',
      render: rowData => <DropDownMenu
        id={rowData.id}
        recording={rowData.twilioRecordingUrl}
        handleDeleteRow={handleSetOnDeleteDialogId}
      />,
    } : {},
  ];

  const handleSetOnDeleteDialogId = (id) => {
    setOnDeleteDialog(true);
    setOnDeleteDialogId(id);
  };

  const handleDeleteCallRecording = () => {
    setOnDeleteDialog(false);

    deleteCallRecording({ id: onDeleteDialogId });
  };

  const handleShowCallDetailsDialog = (open = false, url = null) => {
    setShowCallDetailsDialog(open);
    setSummaryUrlHref(url);
  };

  useEffect(() => {
    tableRef.current.onQueryChange();
  }, [groupId, from, to, rep]);

  return (
    <>
      <MaterialTable
        tableRef={tableRef}
        columns={columns}
        components={{
          OverlayLoading: () => {
            return (
              userData?.customer?.is_subaccount === false
                ? (<div className={classes.overlayLoading}><img alt="loader" src={Loader} /></div>)
                : (
                    <div className={classes.overlayLoading}>
                      <CircularProgress sx={{ color: '#026de5', strokeLinecap: 'round' }} size={50} thickness={3} />
                    </div>
                  )
            );
          },
        }}
        data={(query) => {
          const { page, orderBy, orderDirection } = query;

          return getCallRecordings({ page, orderBy: orderBy?.field || '', orderDirection, groupId, from, to, rep })
            .then((responce) => {
              const { items: dataSets, total: totalCount } = responce.payload.data;

              return {
                data: JSON.parse(JSON.stringify(dataSets)),
                page: page,
                totalCount: (totalCount * 1),
              };
            })
        }}

        options={{
          headerStyle: {
            paddingLeft: 15,
            paddingRight: 15,
          },
          cellStyle: {
            paddingLeft: 15,
            paddingRight: 15,
          },
          paging: true,
          pageSize: 10,
          pageSizeOptions: [],
          sorting: false,
          thirdSortClick: false,
        }}
        localization={{
          header: {
            actions: '',
          },
        }}
      />

      <Dialog
        open={onDeleteDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Warning!</DialogTitle>

        <DialogContent>
          <DialogContentText color="secondary">Are you sure you want to delete this call recording?</DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button
            color="secondary"
            variant="outlined"
            disableElevation
            onClick={() => {
              setOnDeleteDialog(false);
              setOnDeleteDialogId(null);
            }}
          >
            Cancel
          </Button>

          <Button
            color="secondary"
            variant="contained"
            disableElevation
            onClick={() => {
              handleDeleteCallRecording();
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        aria-labelledby="show-call-details"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="lg"
        open={showCallDetailsDialog}
      >
        <DialogContent
          style={{overflowY:'hidden'}}
        >
          <iframe
            src={summaryUrlHref}
            title="Call details"
            width="100%"
            height="100%"
            frameBorder="0"
            style={{minHeight:'800px'}}
          ></iframe>
        </DialogContent>

        <DialogActions>
          <Button
            color="secondary"
            variant="outlined"
            disableElevation
            onClick={() => {
              handleShowCallDetailsDialog();
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
