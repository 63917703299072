/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import {
  Grid,
  makeStyles,
  List,
  ListItemText,
  ListItem,
  TextField,
  withStyles,
  Typography,
  Popover,
  Button,
  InputAdornment,
} from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import {
  subDays,
  startOfDay,
  endOfDay,
  subMonths,
  startOfMonth,
  endOfMonth,
  subYears,
  startOfYear,
  endOfYear,
  isWithinInterval,
  differenceInDays,
  differenceInYears,
  isFirstDayOfMonth,
  isLastDayOfMonth,
  isSameMonth,
  isPast,
} from 'date-fns';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const ListText = withStyles(() => ({
  root: {
    fontWeight: 700,
  },
}))(ListItemText);

const useStyles = makeStyles(theme => ({
  root: {
    // padding: theme.spacing(3, 0),
  },
  dateInterval: {
    backgroundColor: 'rgba(42, 151, 183, 0.3)',
    borderRadius: '50%',
    // margin: '0 1px',
    '& .MuiPickersDay-day .MuiTypography-root': {
      // color: '#fff',
      fontWeight: '700 !important',
    },
  },
  container: {
    display: 'flex',
    padding: theme.spacing(4, 2),
    flexDirection: 'column',
    '& >*:last-child': {
      flexGrow: 1,
    },
  },
  letterSpacing: {
    letterSpacing: '-0.05em',
  },
  containerBottom: {
    display: 'flex',
    alignItems: 'center',
  },
  dateLabel: {
    display: 'flex',
    alignItems: 'center',
  },
  leftContainer: {
    padding: theme.spacing(3, 0),
    borderRight: '1px solid rgba(0, 0, 0, 0.12)',
  },
  selectInput: {
    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: 7,
    },
  },
}));

export default function DateRangePicker({ onChange, from, to }) {
  const classes = useStyles();
  const [date, setDate] = useState({ from: startOfDay(new Date(from)), to: endOfDay(new Date(to)) });
  const [confirmedDate, setConfirmedDate] = useState({ from: startOfDay(new Date(from)), to: endOfDay(new Date(to)) });
  const [selected, setSelected] = useState({
    today: true,
  });
  const [confirmedSelected, setConfirmedSelected] = useState({
    today: true,
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const setTodayDate = () => {
    const todayDate = new Date();
    const startTodayDate = startOfDay(todayDate);
    const endTodayDate = endOfDay(todayDate);

    setDate({ from: startTodayDate, to: endTodayDate });
    setSelected({ today: true });
  };

  const setYesterdayDate = () => {
    const yesterdayDate = subDays(new Date(), 1);
    const startYesterdayDate = startOfDay(yesterdayDate);
    const endYesterdayDate = endOfDay(yesterdayDate);

    setDate({ from: startYesterdayDate, to: endYesterdayDate });
    setSelected({ yesterday: true });
  };

  const setLast7DaysDate = () => {
    const todayDate = new Date();
    const last7day = subDays(todayDate, 7);
    const startLast7dayDate = startOfDay(last7day);
    const endLast7dayDate = endOfDay(subDays(todayDate, 1));// endOfDay(todayDate);

    setDate({ from: startLast7dayDate, to: endLast7dayDate });
    setSelected({ last7Days: true });
  };

  const setLast30DaysDate = () => {
    const todayDate = new Date();
    const last30day = subDays(todayDate, 30);
    const startLast30dayDate = startOfDay(last30day);
    const endLast30dayDate = endOfDay(subDays(todayDate, 1));// endOfDay(todayDate);

    setDate({ from: startLast30dayDate, to: endLast30dayDate });
    setSelected({ last30Days: true });
  };

  const setThisMonthDate = () => {
    const thisMonth = new Date();
    const startOfThisMonth = startOfMonth(thisMonth);
    const endOfThisMonth = endOfMonth(thisMonth);

    setDate({ from: startOfThisMonth, to: endOfThisMonth });
    setSelected({ thisMonth: true });
  };

  const setLastMonthDate = () => {
    const lastMonth = subMonths(new Date(), 1);
    const startOfLastMonth = startOfMonth(lastMonth);
    const endOfLastMonth = endOfMonth(lastMonth);

    setDate({ from: startOfLastMonth, to: endOfLastMonth });
    setSelected({ lastMonth: true });
  };

  const setLastYear = () => {
    const lastYear = subYears(new Date(), 1);
    const startOfLastYear = startOfYear(lastYear);
    const endOfLastYear = endOfYear(lastYear);

    setDate({ from: startOfLastYear, to: endOfLastYear });
    setSelected({ lastYear: true });
  };

  const setCustomDate = () => {
    const todayDate = new Date();
    const startTodayDate = startOfDay(todayDate);
    const endTodayDate = endOfDay(todayDate);

    setDate({ from: startTodayDate, to: endTodayDate });
    setSelected({ custom: true });
  };

  const setStartDate = calendarDate => {
    if (selected.custom) {
      setDate({ ...date, from: calendarDate });
    }
  };

  const setEndDate = calendarDate => {
    if (selected.custom) {
      setDate({ ...date, to: calendarDate });
    }
  };

  const selectedValue = () => {
    if (
      isFirstDayOfMonth(confirmedDate.from) &&
      isLastDayOfMonth(confirmedDate.to) &&
      isSameMonth(confirmedDate.to, confirmedDate.from) &&
      isPast(confirmedDate.from) &&
      isPast(confirmedDate.to)
    ) {
      return 'Last Month';
    }
    if (
      isFirstDayOfMonth(confirmedDate.from) &&
      isLastDayOfMonth(confirmedDate.to) &&
      isSameMonth(confirmedDate.to, confirmedDate.from) &&
      isPast(confirmedDate.from) &&
      !isPast(confirmedDate.to)
    ) {
      return 'This Month';
    }

    if ((differenceInDays(new Date(), confirmedDate.from) === 30) &&
      (differenceInDays(confirmedDate.to, confirmedDate.from) === 29) &&
      (differenceInDays(confirmedDate.from, confirmedDate.to) !== 0)) {
      return 'Last 30 Days';
    }

    if ((differenceInDays(new Date(), confirmedDate.from) === 7) &&
      (differenceInDays(confirmedDate.to, confirmedDate.from) === 6) &&
      (differenceInDays(confirmedDate.from, confirmedDate.to) !== 0)) {
      return 'Last 7 Days';
    }

    if (
      differenceInDays(new Date(), confirmedDate.from) === 1 &&
      isPast(confirmedDate.from) &&
      isPast(confirmedDate.to)
    ) {
      return 'Yesterday';
    }

    if ((differenceInDays(confirmedDate.to, confirmedDate.from) === 0) &&
      (differenceInDays(new Date(), confirmedDate.from) === 0)) {
      return 'Today';
    }

    if (differenceInYears(new Date(), confirmedDate.from) === 1) {
      return 'Last Year';
    }
    return 'Custom';
  };

  const renderWrappedWeekDay = (calendarDate, selectedDate, dayInCurrentMonth, Element) => {
    const dayIsBetween = isWithinInterval(calendarDate, { start: date.from, end: date.to });
    return dayIsBetween ? (
      <div className={classes.dateIntervaleWrapper}>
        <div className={clsx(dayInCurrentMonth && classes.dateInterval)}>{Element}</div>
      </div>
    ) : (
      Element
    );
  };

  const handleApply = () => {
    setAnchorEl(null);
    setConfirmedDate({ ...date });
    setConfirmedSelected({ ...selected });
  };

  const handleCancel = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    onChange(confirmedDate);
    return () => { };
  }, [confirmedDate]);

  useEffect(
    () => () => {
      setDate({ ...confirmedDate });
      setSelected({ ...confirmedSelected });
    },
    [],
  );

  return (
    <>
      <TextField
        value={selectedValue()}
        variant="outlined"
        color="secondary"
        size="small"
        className={classes.selectInput}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end" style={{ cursor: 'pointer' }}>
              <ArrowDropDownIcon color="action" />
            </InputAdornment>
          ),
          readOnly: true,
          style: { cursor: 'pointer' },
        }}
        onClick={event => {
          setAnchorEl(event.currentTarget);
        }}
      />

      <Popover
        open={open}
        className={classes.popover}
        anchorEl={anchorEl}
      //  onClose={handleClose}
      >
        <div className={classes.root}>
          <Grid container>
            <Grid item xs md={2}>
              <div className={classes.leftContainer}>
                <div>
                  <List>
                    <ListItem button selected={selected.custom} onClick={setCustomDate} className={classes.letterSpacing}>
                      <ListText disableTypography primary="Custom Range" />
                    </ListItem>
                  </List>
                </div>
                <div>
                  <List component="div">
                    <ListItem button selected={selected.today} onClick={setTodayDate}>
                      <ListText disableTypography primary="Today" />
                    </ListItem>
                    <ListItem button selected={selected.yesterday} onClick={setYesterdayDate}>
                      <ListText disableTypography primary="Yesterday" />
                    </ListItem>
                    <ListItem button selected={selected.last7Days} onClick={setLast7DaysDate}>
                      <ListText disableTypography primary="Last 7 Days" />
                    </ListItem>
                    <ListItem button selected={selected.last30Days} onClick={setLast30DaysDate}>
                      <ListText disableTypography primary="Last 30 Days" />
                    </ListItem>
                    <ListItem button selected={selected.thisMonth} onClick={setThisMonthDate}>
                      <ListText disableTypography primary="This Month" />
                    </ListItem>
                    <ListItem button selected={selected.lastMonth} onClick={setLastMonthDate}>
                      <ListText disableTypography primary="Last Month" />
                    </ListItem>
                    <ListItem button selected={selected.lastYear} onClick={setLastYear}>
                      <ListText disableTypography primary="Last Year" />
                    </ListItem>
                  </List>
                </div>
              </div>
            </Grid>
            <Grid item md={10} className={classes.container}>
              <Grid container direction="row">
                <Grid item md={6}>
                  <Typography align="center" variant="overline" component="div">
                    Start Date
                  </Typography>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      value={date.from}
                      maxDate={selected.custom && date.to}
                      onChange={setStartDate}
                      renderDay={renderWrappedWeekDay}
                      variant="static"
                      openTo="date"
                      disableToolbar
                      fullWidth
                      orientation="portrait"
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item md={6}>
                  <Typography align="center" variant="overline" component="div">
                    End Date
                  </Typography>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      value={date.to}
                      minDate={selected.custom && date.from}
                      onChange={setEndDate}
                      renderDay={renderWrappedWeekDay}
                      variant="static"
                      openTo="date"
                      disableToolbar
                      fullWidth
                      orientation="portrait"
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              </Grid>
              <Grid container>
                <Grid container spacing={2} justifyContent="flex-end" alignItems="center">
                  <Grid item>
                    <Button variant="outlined" color="primary" onClick={handleCancel}>
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button variant="contained" color="primary" disableElevation onClick={handleApply}>
                      Apply
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Popover>
    </>
  );
}

DateRangePicker.defaultProps = {
  onChange: () => { },
  from: new Date().toISOString(),
  to: new Date().toISOString(),
};
